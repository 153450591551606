<template>
  <div class="info-table">
    <table v-if="deviceInfo">
      <tr><td>Kernel Version:</td><td>{{ deviceInfo.kernel_version }}</td></tr>
      <tr><td>Up Time:</td><td>{{ deviceInfo.uptime }}</td></tr>
      <tr><td>Local IP:</td><td>{{ deviceInfo.local_ip }}</td></tr>
      <tr><td>Memory Usage:</td><td>{{ deviceInfo.memory_usage }}</td></tr>
      <tr><td>Disk Usage:</td><td>{{ deviceInfo.disk_usage }}</td></tr>
      <tr><td>Database Usage:</td><td>{{ deviceInfo.database_usage }}</td></tr>
      <tr><td>Status:</td><td>{{ deviceInfo.status }}</td></tr>
    </table>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'InfoTable',
  data() {
    return {
      deviceInfo: null,
      error: null
    };
  },
  mounted() {
    fetch("/backend/infotable.php")
      .then(response => {
        if (!response.ok) {
          throw new Error('Request failed, network respond error');
        }
        return response.json();
      })
      .then(data => {
        this.deviceInfo = data.deviceinfo;
        console.log(this.deviceInfo); // Error Logging
      })
      .catch(error => {
        this.error = error.message;
      });
  }
}
</script>

<style scoped>
.info-table {
    width: 100%;
    margin: 10px;
    border-collapse: collapse;
}

.info-table table {
    width: 100%;
    border-collapse: collapse;
}

.info-table td {
    border: 1px solid #b4b0b0;
    padding: 10px 15px;
    text-align: left;
    font-size: 13px;
    color: #333;
}

.info-table td:first-child {
    font-weight: bold;
    width: 12%;  /* Column width */

}

</style>

