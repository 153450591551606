<template>
  <div class="header">
    <Header />
  </div>
  <div class="content">
    <Menu />
    <div class="info-container">
      <div class="table-section">
        <h2 class="table-title">iOT Gateway Info:</h2>
        <InfoTable />
      </div>
      <div class="map-section">
        <h2 class="map-title">Location Info:</h2>
      </div>
    </div>
  </div>
</template>
  
<script>
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';
import InfoTable from '@/components/InfoTable.vue';
  
export default {
  name: 'DeviceInfo',
  
  components: {
    Header,
    Menu,
    InfoTable
}
  }
</script>
  
<style scoped>
.header{
  display: flex;
  flex-direction: column;
}

.content{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.info-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 15%);
  margin-left: 10px;
}

.table-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-title {
  width: 100%;
  text-align: left;
  font-size: 15px;
  margin: 15px 0px 0px 15px;
  text-decoration: underline;
}

.map-section {
  width: 100%;
  margin-top: 20px;
}

.map-title {
  width: 100%;
  text-align: left;
  font-size: 15px;
  margin: 0px 0px 0px 15px;
  text-decoration: underline;
}
</style>
    