<template>
    <div class="menu">
        <router-link to="/home"><ion-icon name="bookmark-outline"></ion-icon>Home</router-link>
        <router-link to="/intake-station"><ion-icon name="bookmark-outline"></ion-icon>Palm Kernel Intake Station</router-link>
        <router-link to="/weighing-system"><ion-icon name="bookmark-outline"></ion-icon>Palm Kernel Weighing System</router-link>
        <router-link to="/first-pressing"><ion-icon name="bookmark-outline"></ion-icon>Palm Kernel First Pressing</router-link>
        <router-link to="/second-pressing"><ion-icon name="bookmark-outline"></ion-icon>Palm Kernel Second Pressing</router-link>
        <router-link to="/oil-recovery-station"><ion-icon name="bookmark-outline"></ion-icon>Oil Recovery Station</router-link>
        <router-link to="/power-monitoring"><ion-icon name="bookmark-outline"></ion-icon>Power Monitoring</router-link>
        <router-link to="/report"><ion-icon name="bookmark-outline"></ion-icon>Report</router-link>
        <router-link to="/system-logging"><ion-icon name="bookmark-outline"></ion-icon>System Logging</router-link>
        <router-link to="/user-setting"><ion-icon name="bookmark-outline"></ion-icon>User Setting</router-link>
        <router-link to="/device-info"><ion-icon name="bookmark-outline"></ion-icon>Device Info</router-link>
    </div>
</template>

<script>
export default {
    name: 'Menu',
}
</script>

<style scoped>
.menu {
    width: 13%;                  /* Take up 15% of the parent's width */
    height: calc(100vh - 100px); /* Assume header height is 100px; adjust if different */
    background-color: #323335;
    padding: 10px;
    box-sizing: border-box;
    border-right: 2px solid #0e0d0d;

}
.menu a {
  display: flex;              /* Add flex to align items horizontally */
  align-items: center;        /* Vertically align items in the center */
  padding: 15px 5px;
  text-decoration: none;
  color: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 13px;
  text-align: left;
  transition: all 0.2s;
}

.menu a:hover {
  color: #000;
  background-color: #9e9eb1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

.menu a:hover::before,
.menu a:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: right;
  transition: transform 0.3s ease-out;
}

.menu a:hover::before {
  background-color: #d0d0da;
  transform: scaleX(0);
  transform-origin: left;
}

.menu a:hover::after {
  background-color: #d0d0da;
  transform: scaleX(1);
}

ion-icon{
  margin-right: 5px;
}

</style>
