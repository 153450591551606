<template>
    <div class="login-container">
        <div class="login-box">
            <Logo />
            <h2>Login</h2>
            <form @submit.prevent="handleLogin">
                <div class="input-box">
                    <label for="username">Username</label>
                    <input type="text" id="username" v-model="username" required>
                </div>
                <div class="input-box">
                    <label for="password">Password</label>
                    <input type="password" id="password" v-model="password" required>
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
        <div class="footer">
            <p>2023 IMAX INDUSTRIES SDN BHD - All Rights Reserved.</p>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';



export default {
    data() {
        return {
            username: '',
            password: ''
        };
    },
    methods: {
        handleLogin() {
            console.log("Username:", this.username);
            console.log("Password:", this.password);
        }
    },
    components: { Logo }
}
</script>

<style scoped>
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #1E4E79;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-box {
    width: 400px;
    padding: 40px 30px;
    border-radius: 8px;
    border: 1px solid #b4b0b0;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    background: #f7f7f8;
    text-align: center;
}

h2 {
    margin-bottom: 30px;
    color: #333;
    width: 100%;
    text-align: center;
}

.input-box {
    margin-bottom: 20px;
    width: 100%;
}

label {
    display: block;
    margin-bottom: 10px;
    color: #555;
    width: 100%;
    text-align: center;
}

input {
    width: 93%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.2s;
}

input:focus {
    border-color: #007BFF;
}

button {
    width: 100%;
    padding: 12px;
    background: #007BFF;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s;
}

button:hover {
    background: #0056b3;
}

.footer{
    color: white;
}
</style>
