<template>
  <div>
    <Header />
    <Menu />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';

export default {
    name: 'FirstPressing',

    components: { 
      Header, 
      Menu 
    }
}
</script>

<style scoped>
  /* Add any specific styles for this component here. The 'scoped' attribute ensures these styles only apply to this component. */
</style>
  