import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

//Browser css to remove default margin & padding
import '@/assets/styles.css';  

//Pages
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import WeighingSystem from './views/WeighingSystem.vue';
import IntakeStation from './views/IntakeStation.vue';
import FirstPressing from './views/FirstPressing.vue';
import SecondPressing from './views/SecondPressing.vue';
import OilRecoveryStation from './views/OilRecoveryStation.vue';
import PowerMonitoring from './views/PowerMonitoring.vue';
import Report from './views/Report.vue';
import SystemLogging from './views/SystemLogging.vue';
import UserSetting from './views/UserSetting.vue';
import DeviceInfo from './views/DeviceInfo.vue';


const routes = [
    { path: '/', redirect: '/login' },   // Redirect to login by default
    { path: '/login', component: Login, name: 'Login' },
    { path: '/home', component: Home, name: 'Home' },
    { path: '/intake-station', component: IntakeStation, name: 'PalmKernelIntakeStation' },
    { path: '/weighing-system', component: WeighingSystem, name: 'WeighingSystem' },
    { path: '/first-pressing', component: FirstPressing, name: 'PalmKernelFirstPressing' },
    { path: '/second-pressing', component: SecondPressing, name: 'PalmKernelSecondPressing' },
    { path: '/oil-recovery-station', component: OilRecoveryStation, name: 'OilRecoveryStation' },
    { path: '/power-monitoring', component: PowerMonitoring, name: 'PowerMonitoring' },
    { path: '/report', component: Report, name: 'Report' },
    { path: '/system-logging', component: SystemLogging, name: 'SystemLogging' },
    { path: '/user-setting', component: UserSetting, name: 'UserSetting' },
    { path: '/device-info', component: DeviceInfo, name: 'DeviceInfo' }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp(App);
app.use(router);

// Global Registration 
app.mount('#app');
