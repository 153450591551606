<template>
    <div class="logo">
      <img src="@/assets/logo.svg" alt="IMAX INDUSTRIES LOGO">
    </div>
</template>
  
<script>
  export default {
    name: 'Logo'
  }
</script>
  
<style scoped>
.logo {
  flex-basis: 10%;
  padding: 0;
}

.logo img {
  max-height: 100px;
  width: auto;
}
</style>
  