<template>
  <div class="header">
    <Logo />
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

export default {
  name: 'Header',
  components: {
    Logo
  }
}
</script>

<style scoped>
.header {
  height: 100px;
  background-color: #f7f7f8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  border-bottom: 2px solid #b4b0b0;
}

.header .logo {
  margin-left: 60px;
}
</style>
